.avatar {
  background: #999;
  border-radius: 50%;
  // padding: 0.4rem;

  display: inline-flex;
  overflow: hidden;

  // width: 2.3rem;
  // text-align: center;

  .avatar-inis {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 38px;  
    color: white;    
    text-decoration: none;

    font-weight: 400;    
  }

  &.avatar-small{    
    .avatar-inis {
      height: 20px;
      width: 20px;  
      font-size: 0.65rem;
    }    
  }

  &.avatar-xsmall{
    .avatar-inis {
      height: 15px;
      width: 15px;  
      font-size: 0.55rem;
    }        
  }  
}

.bg-avatar-internal {
  background-color: #077f8c !important;
}

.bg-avatar-external {
  background-color: #e83f6f !important;
}
