#bnf-table-wrapper {
  thead {
    border-bottom: solid 2px #666;

    tr.bnf-table-captions {
      th {
        font-weight: 400;
        border-bottom-width: 0;
        background-color: white;
      }
    }
    tr.bnf-table-filters {
      th {
        padding-top: 0;
      }
    }
  }
  tbody {
    background-color: white;
  }
}
