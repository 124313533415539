#top-menu {
  .dropdown .nav-link {
    color: rgba(255, 255, 255, 0.55);
  }
  .dropdown .nav-link:hover {
    color: rgba(255, 255, 255, 0.75);
  }

  .icon {font-size: 1.3rem;}

}
