@import 'variables';

body {
  height: 100vh;
}

#root {
  // background-color: $bg-color-main;
  height: 100vh;
}

.app-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'header'
    'main';
  width: 100%;
  height: 100%;
}

.header {
  grid-area: header;
}
.main {
  grid-area: main;
  overflow-y: auto;
}

.main-container {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}
p {
  color: black;
}

.r {
  text-align: right;
}

.c {
  text-align: center;
}

.fs-9 {
  font-size: 0.9rem;
}

.fs-8 {
  font-size: 0.8rem;
}

.bg-internal {
  background-color: $bg-color-internal !important;
}

.bg-external {
  background-color: $bg-color-external !important;
}

.bg-white {
  background-color: white !important;
}

.bg-light-gray {
  background-color: #fafafa !important;
}

.mw-600 {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.mw-1200 {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.cursor-pointer {
  cursor: pointer;
}

.badge-top-index {
  font-size: 0.6rem;
  margin-left: -5px;
  vertical-align: top;
}

.nowrap {
  white-space: nowrap;
}

h4 {
  font-weight: 300;
}

.oy-auto {
  overflow-y: auto;
}

.text-line-through {
  text-decoration: line-through;
}

.form-control:focus {
  box-shadow: none;
}
