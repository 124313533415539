.kb {
  //   .kb-items {
  //     overflow-y: auto;
  //   }
  //   .kb-text {
  //     border: solid 1px #ccc;
  //     padding: 1.2rem;
  //     overflow-y: auto;
  //   }

  .kb-grid {
    display: grid;

    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr 4fr;

    gap: 0px;
    height: 100%;
  }

  .kb-filter {
    grid-row-start: 1;
    grid-column-start: 1;

    grid-row-end: 2;
    grid-column-end: 2;
    background-color: #f0f0f0;
    padding: 1rem;
  }
  .kb-title {
    grid-row-start: 1;
    grid-column-start: 2;

    grid-row-end: 2;
    grid-column-end: 3;
    background-color: #f0f0f0;
    padding: 1rem;
  }
  .kb-index {
    grid-row-start: 2;
    grid-column-start: 1;

    grid-row-end: 3;
    grid-column-end: 2;
    overflow-y: auto;
    padding: 1rem 1rem 0 0;
  }
  .kb-text {
    grid-row-start: 2;
    grid-column-start: 2;

    grid-row-end: 3;
    grid-column-end: 3;
    overflow-y: auto;
    padding: 1rem;
    border-left: solid 1px #ccc;
  }

  .react-select__menu {
    z-index: 1000;
  }
}
