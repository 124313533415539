.script-grid{  
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto 1fr;
  gap: 0 0;
  grid-auto-flow: row;
  grid-template-areas:
    "script-header-list script-header-detail"
    "script-list script-detail";
  width: 100%;
  background-color: #f0f0f0;
  max-height: calc(100vh - 100px);

  .script-header-list{grid-area: script-header-list;border-bottom:solid 2px #ccc;}
  .script-header-detail{grid-area: script-header-detail;border-bottom:solid 2px #ccc;}
  .script-list{grid-area: script-list; overflow-y: auto;}
  .script-detail{grid-area: script-detail;overflow-y: auto;background-color: #ffffff;}
}


